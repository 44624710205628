import * as React from "react"
import { Box, createTheme, Grid, Stack, ThemeProvider, Typography } from "@mui/material";
import { Button } from "gatsby-theme-material-ui";
import { StandardPageContainer } from "../components/reviewskew/standardpage/StandardPageContainer";
import { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import theme from "../gatsby-theme-material-ui-top-layout/theme";


const amazonTag = theme.affiliates?.amazon?.tag

const Onboarding = () => {
  const [cardId, setCardId] = useState("summarize")

  return (
    <StandardPageContainer title="Onboarding - Review Skew">
      
      <Typography variant="h2" component="h1" textAlign="center">
        You've installed Review Skew!
      </Typography>
      
      <Typography textAlign="center" margin={4}>
        Here's how to improve your shopping experience on Amazon
      </Typography>


      <Grid container alignItems="center" justifyContent="center" spacing={2}>

        <Grid item>
          <Button variant="contained" onClick={() => {setCardId("summarize")}}>
            <Typography variant="h6" component="div">📖 Summarize Reviews</Typography>
          </Button>
        </Grid>

        <Grid item>
          <Button variant="contained" onClick={() => {setCardId("prosandcons")}}>
            <Typography variant="h6" component="div">😃 See Pros and Cons 😭</Typography>
          </Button>
        </Grid>

        <Grid item>
          <Button variant="contained" onClick={() => {setCardId("tryit")}}>
            <Typography variant="h6" component="div">📦 Try it out!</Typography>
          </Button>
        </Grid>

      </Grid>


      <Grid container alignItems="center" justifyContent="space-around" spacing={2} marginTop={4} border={1} borderRadius={4} padding={2}>
        <Grid item textAlign="center">
          {cardId == "summarize" && (
              <StaticImage src="../components/reviewskew/onboarding/images/crayola-really-short.png" alt="Small example of Review Skew plugin on amazon page" width={500} />
          )}
          {cardId == "prosandcons" && (
              <StaticImage src="../components/reviewskew/onboarding/images/crayola-really-long.png" alt="Large example of Review Skew plugin on amazon page" width={500} />
          )}
          {cardId == "tryit" && (
              <StaticImage src="../images/icon-transparent.png" alt="Large Review Skew Logo" width={200} />
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          {cardId == "summarize" && (
            <Box>
              <Typography variant="h4">
                Summarize Reviews
              </Typography>

              <Typography marginTop={2}>
                Visit any Amazon product listing in your Chrome Browser and look for the "Review Skew - AI Summary" just below the product name.
              </Typography>
            </Box>
          )}
          {cardId == "prosandcons" && (
            <Box>
              <Typography variant="h4">
                Easy Pro and Con Summaries
              </Typography>

              <Typography marginTop={2}>
                Click <Typography variant="button" component="span" color={theme.palette.primary.main} sx={{ fontWeight: "bold"}}>∨ Show Pros &amp; Cons ∨</Typography> below the summary to get a bullet-list of positive and negative opinions about your product from reviews across the web.
              </Typography>
            </Box>
          )}
          {cardId == "tryit" && (
            <Box>
              <Typography variant="h4">
                Try It Out!
              </Typography>

              <Typography marginTop={2}>
                Click the link below to view popular products on Amazon and click on any product to get started!
              </Typography>

              <a href={"https://www.amazon.com/Best-Sellers/zgbs?tag=" + amazonTag}>Let's try it out with a popular Amazon Product →</a>
            </Box>
          )}
        </Grid>
      </Grid>
      
    </StandardPageContainer>
  )
}

export default Onboarding
